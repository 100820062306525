import type { DecodedToken } from "context/userContext";
import jwtDecode from "jwt-decode";
import { apiEndpoint } from "../constants";

export type ReqMethods = "POST" | "GET" | "PUT" | "DELETE";

export interface FetchApiOptions {
  method: ReqMethods;
  auth?: boolean;
  data?: { [key: string]: unknown };
  file?: Blob;
}

export class HttpError extends Error {
  public constructor(
    public code: number,
    message?: string
  ) {
    super(message);
  }
}

export async function fetchApi<ResponseData>(
  endpoint: string,
  opts: FetchApiOptions
) {
  const reqOpts: RequestInit = {};
  reqOpts.method = opts.method;
  if (opts.auth) {
    const tokenString = localStorage.getItem("token");
    // Redirect if token is expired
    if (tokenString && isTokenExpired(tokenString)) {
      localStorage.removeItem("userData");
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("token");
      window.location.assign(window.location.origin);
    }
    if (!tokenString) {
      throw new Error("No token found");
    }
    const parsedToken = JSON.parse(tokenString);

    if (!parsedToken) {
      throw new Error("Invalid token format");
    }

    reqOpts.headers = {
      Authorization: `Bearer ${parsedToken}`,
    };
  }

  if (opts.data) {
    reqOpts.headers = {
      ...reqOpts.headers,
      "Content-Type": "application/json",
    };
    reqOpts.body = JSON.stringify(opts.data);
  }

  if (opts.file) {
    reqOpts.body = opts.file;
  }
  const response = await fetch(apiEndpoint + endpoint, reqOpts);
  if (!response.ok) {
    let error;

    if (response.headers.get("Content-Type")?.includes("application/json")) {
      error = ((await response.json()) as { message: string }).message;
    } else {
      error = await response.text();
    }

    throw new HttpError(response.status, error);
  }

  let result;

  if (response.headers.get("Content-Type")?.includes("application/json")) {
    result = (await response.json()) as ResponseData;
    return result;
  }

  if (
    response.headers.get("Content-Type")?.includes("text/html; charset=utf-8")
  ) {
    result = (await response.text()) as ResponseData;
    return result;
  }

  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isDecodedToken = (token: any): token is DecodedToken =>
  token && token.iat && token.ciamId;

export function isTokenExpired(tkn?: string | null | DecodedToken) {
  const now = Math.floor(Date.now() / 1000);

  if (!tkn) {
    return false;
  }
  const token = isDecodedToken(tkn) ? tkn : jwtDecode<DecodedToken>(tkn);

  if (!token.exp) {
    return false; // no expiration date
  }

  // Token exp will stay in seconds
  const expDate = new Date(token.exp);

  if (now > expDate.getTime()) {
    return true; // token expired
  }

  return false; // valid token
}
