import { Row } from "@tanstack/react-table";
import { ChallengeForm } from "components/forms/ChallengeForm/ChallengeForm";
import { ModalWrapper } from "components/modals/ModalWrapper/ModalWrapper";
import { ChallengesCols } from "components/tables/ReactTable/ChallengesColumn";
import DataTable from "components/tables/ReactTable/DataTable";
import Button from "components/ui/Button/Button";
import { Card } from "components/ui/Card/Card";
import { DashboardMainHeader } from "components/ui/DashboardMainHeader/DashboardMainHeader";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { themeContext } from "context/themeContext";
import { useSWRFetchAPIWithSchemaValidation } from "hooks/useSWRFetchAPI";
import { adminRoutesMetadata } from "routes/adminRoutesMetadata";
import { CHALLENGE_STATUS, CHALLENGE_TYPE } from "types/challenge.types";
import {
  AdminBadgeController_getAll,
  AdminChallengeController_getAllChallenges,
  ChallengeDto,
} from "types/schemas";
import { z } from "zod";
import { ChangeEvent, useCallback, useContext, useMemo, useState } from "react";
import s from "./ChallengesSection.module.scss";
import { ExpandedChallengeRow } from "./ExpandedChallengeRow";

const { title, description } = adminRoutesMetadata.challenges;

export function ChallengesSection() {
  const { data, isLoading, error, mutate } = useSWRFetchAPIWithSchemaValidation(
    AdminChallengeController_getAllChallenges,
    {
      parameters: {}, // TODO: add admin filters
      auth: true,
    }
  );

  const { data: badgesData } = useSWRFetchAPIWithSchemaValidation(
    AdminBadgeController_getAll,
    {
      parameters: {
        contractAddress: process.env
          .REACT_APP_ACHIEVEMENT_TOKEN_CONTRACT_ADDRESS as string,
      },
      auth: true,
    }
  );

  const [searchValue, setSearchValue] = useState<string>();
  const { theme } = useContext(themeContext);

  // Table data
  const tableCols = useMemo(() => ChallengesCols, []);
  // Shows the dropdown info section for an item
  const renderExpandedInfo = useCallback(
    ({ row }: { row: Row<z.infer<typeof ChallengeDto>> }) => {
      const rowValues = {
        id: row.getValue("id") as string,
        name: row.getValue("name") as string,
        status: row.getValue("status") as CHALLENGE_STATUS,
        entryUrl: row.getValue("entryUrl") as string,
        typeOfChallenge: row.getValue("typeOfChallenge") as CHALLENGE_TYPE,
        checkInAmount: row.getValue("checkInAmount") as number | undefined,
        previousChallengeId: row.getValue("previousChallengeId") as
          | string
          | undefined,
      };

      const baseChallengeId = data.find(
        (challenge) => challenge.id === rowValues.id
      )?.baseChallengeId;
      return (
        <ExpandedChallengeRow
          id={rowValues.id}
          name={rowValues.name}
          status={rowValues.status}
          entryUrl={rowValues.entryUrl}
          isMultiChallenge={
            !!rowValues.checkInAmount && rowValues.checkInAmount > 0
          }
          mutate={mutate}
          typeOfChallenge={rowValues.typeOfChallenge}
          badgesData={badgesData.map((badgeData) => ({
            name: badgeData.name,
            id: badgeData.id,
          }))}
          challengesData={data
            .filter((challenge) => challenge.id !== rowValues.id)
            .map((challenge) => ({
              name: challenge.name,
              id: challenge.id,
              checkInAmount: challenge.checkInAmount,
            }))}
          baseChallengeId={baseChallengeId}
          baseChallengeName={
            data.find((challenge) => challenge.id === baseChallengeId)?.name
          }
          previousChallenge={
            data.find(
              (challenge) => challenge.id === rowValues.previousChallengeId
            )?.name
          }
          closeExpanded={() => row.toggleExpanded(false)}
        />
      );
    },
    [badgesData, data, mutate]
  );

  function handleSearchInput(e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  if (isLoading) return <h6>Loading...</h6>;
  if (error) return <h2>Error Occured</h2>;

  return (
    <>
      <DashboardMainHeader title={title} description={description} />
      <Card as="section" className={s["section-wrapper"]}>
        <div className={s["section-nav"]}>
          <div className={s["search-wrapper"]}>
            <InputField
              onChange={handleSearchInput}
              label="Search across all attributes"
              className={s["search-field"]}
              type="search"
            />
          </div>

          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button size="large">
                <Icon style={{ height: "1em" }} name="add" />
                <span>Create Challenge</span>
              </Button>
            </Dialog.Trigger>
            <ModalWrapper
              title="Create Challenge"
              className={s["modal-screen"]}
              closeBtn>
              <ChallengeForm
                mutate={mutate}
                badgesData={badgesData.map((badgeData) => ({
                  name: badgeData.name,
                  id: badgeData.id,
                }))}
                challengesData={data
                  .filter(
                    (challenge) =>
                      challenge.checkInAmount && challenge.checkInAmount > 0
                  )
                  .map((challenge) => ({
                    name: challenge.name,
                    id: challenge.id,
                  }))}
              />
            </ModalWrapper>
          </Dialog.Root>
        </div>

        <DataTable
          columns={tableCols(theme === "dark" ? "dark" : "light")}
          data={data}
          expandedComponent={renderExpandedInfo}
          tableClass="table-offers"
          filterValue={{ key: "name", value: searchValue }}
        />
      </Card>
    </>
  );
}
