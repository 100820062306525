/* eslint-disable no-magic-numbers, @typescript-eslint/no-magic-numbers, camelcase */
import { z } from "zod";

export interface ApiRequestParameter {
  name: string;
  type: "Body" | "Path" | "Query" | "Header";
  schema: z.ZodTypeAny;
}

export interface ApiRequest<TZodResponseSchemaType extends z.ZodTypeAny> {
  path: string;
  method: "get" | "post" | "put" | "delete";
  parameters: ApiRequestParameter[];
  responseSchema: TZodResponseSchemaType;
}

export const UserDetailsDto = z
  .object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    walletAddress: z.string().optional(),
    deleted: z.boolean().optional(),
    blacklisted: z.boolean().optional(),
  })
  .passthrough();

export const UserBillingAddress = z
  .object({
    addressId: z.string(),
    country: z.string(),
    city: z.string(),
    street1: z.string(),
    street2: z.string().optional(),
    houseNumber: z.string().optional(),
    postalCode: z.string(),
  })
  .passthrough();

export const UserCheckoutDetailsDto = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    address: UserBillingAddress.optional(),
    mobileNumber: z.string().optional(),
  })
  .passthrough();

export const WalletDto = z
  .object({ walletName: z.string(), walletAddress: z.string() })
  .passthrough();

export const UserAdminDto = z
  .object({
    lastName: z.string(),
    firstName: z.string(),
    email: z.string(),
    isAdmin: z.boolean(),
    isStudioUser: z.boolean().optional(),
    deleted: z.boolean().optional(),
    blacklisted: z.boolean().optional(),
    walletName: z.string(),
    walletAddress: z.string(),
    id: z.string(),
    creation: z.number().optional(),
    updated: z.number().optional(),
  })
  .passthrough();

export const GetAllUsers = z
  .object({
    lastKey: z.object({}).partial().passthrough(),
    users: z.array(UserAdminDto),
  })
  .passthrough();

export const UserBlacklistArgs = z
  .object({ userId: z.string().max(100), isBlacklisted: z.boolean() })
  .passthrough();

export const UserPurchaseProductDto = z
  .object({
    productId: z.string(),
    variantId: z.string(),
    price: z.number(),
    quantity: z.number(),
    totalPrice: z.number(),
    id: z.string(),
    purchaseId: z.string(),
    creation: z.number().optional(),
    updated: z.number().optional(),
  })
  .passthrough();

export const UserPurchaseDto = z
  .object({
    id: z.string(),
    userId: z.string(),
    totalPrice: z.number(),
    adminNote: z.string().optional(),
    deliveryAddress: z.string(),
    status: z.enum(["INITIAL", "IN_PROGRESS", "FULFILLED"]),
    product: UserPurchaseProductDto,
    creation: z.number().optional(),
    updated: z.number().optional(),
  })
  .passthrough();

export const GetAllUserPurchases = z
  .object({
    lastKey: z.object({}).partial().passthrough(),
    orders: z.array(UserPurchaseDto),
  })
  .passthrough();

export const ChangePurchaseStatusArgs = z
  .object({
    status: z.enum(["INITIAL", "IN_PROGRESS", "FULFILLED"]),
    adminNote: z.string().max(2000).optional(),
    userId: z.string().max(100),
  })
  .passthrough();

export const ProductVariantDto = z
  .object({
    name: z.string(),
    quantity: z.number().optional(),
    price: z.number(),
    remainingQuantity: z.number(),
    contentfulRef: z.string(),
    tokenId: z.number(),
    isMaster: z.boolean(),
    id: z.string(),
    productId: z.string(),
    creation: z.number(),
    updated: z.number(),
  })
  .passthrough();

export const ProductDto = z
  .object({
    name: z.string(),
    slug: z.string(),
    type: z.enum(["VOUCHER", "ITEM", "EVENT"]),
    startDate: z.number(),
    endDate: z.number(),
    status: z.enum(["DRAFT", "ACTIVE", "DELETED"]),
    addedBy: z.string(),
    editedBy: z.string().optional(),
    variants: z.array(ProductVariantDto),
    id: z.string(),
    creation: z.number().optional(),
    updated: z.number().optional(),
  })
  .passthrough();

export const CreateOrUpdateProductVariantArgs = z
  .object({
    name: z.string().min(1).max(200),
    price: z.number(),
    quantity: z.number().gte(1).optional(),
    isMaster: z.boolean().optional(),
    toDelete: z.boolean().optional(),
    discountCodesCsvBase64: z.string().optional(),
    attribute: z.string().optional(),
  })
  .passthrough();

export const CreateProductArgs = z
  .object({
    name: z.string().min(1).max(150),
    type: z.enum(["VOUCHER", "ITEM", "EVENT"]),
    startDate: z.number().gte(0),
    endDate: z.number().gte(0),
    slug: z.string().min(1).max(150).optional(),
    variants: z.array(CreateOrUpdateProductVariantArgs),
    useExistingContentfulEntry: z.boolean(),
    subType: z.string().min(1).max(150),
  })
  .passthrough();

export const UpdateProductArgs = z
  .object({
    name: z.string().min(1).max(150),
    type: z.enum(["VOUCHER", "ITEM", "EVENT"]),
    startDate: z.number().gte(0),
    endDate: z.number().gte(0),
    slug: z.string().min(1).max(150).optional(),
    variants: z.array(CreateOrUpdateProductVariantArgs),
    useExistingContentfulEntry: z.boolean(),
    subType: z.string().min(1).max(150),
  })
  .passthrough();

export const ChangeStatusArgs = z
  .object({ status: z.enum(["DRAFT", "ACTIVE", "DELETED"]) })
  .passthrough();

export const BasicChainDataDto = z
  .object({
    balance: z.string(),
    tokenBalance: z.number(),
    badgeBalance: z.string(),
    badgeTotal: z.string(),
    redeemedTokens: z.string(),
  })
  .passthrough();

export const BadgeChainDataDto = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    cid: z.string(),
    contractAddress: z.string(),
    tokenId: z.number(),
    imgSrc: z.string(),
    metadata: z.object({}).partial().passthrough(),
  })
  .passthrough();

export const CreateBadgeArgs = z
  .object({
    name: z.string().min(1).max(150),
    description: z.string().min(1).max(2000),
    fileBase64: z.string().min(1),
  })
  .passthrough();

export const BadgeDto = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    cid: z.string(),
    contractAddress: z.string(),
    tokenId: z.number(),
    creation: z.number(),
    updated: z.number(),
  })
  .passthrough();

export const BalanceDto = z
  .object({ balance: z.number(), expiringTokens: z.number() })
  .passthrough();

export const StatsDto = z
  .object({ totalSupply: z.number(), expiringTokens: z.number() })
  .passthrough();

export const AuthenticateArgs = z
  .object({ code: z.string().min(1).max(3000) })
  .passthrough();

export const AuthResponseDto = z
  .object({
    jwt: z.string(),
    isAdmin: z.boolean(),
    email: z.string(),
    lastName: z.string(),
    firstName: z.string(),
  })
  .passthrough();

export const CartProduct = z
  .object({
    productId: z.string().min(1).max(100),
    variantId: z.string().min(1).max(100),
    quantity: z.number().gte(1).lte(1),
  })
  .passthrough();

export const AddressDto = z
  .object({
    firstName: z.string().min(1).max(100),
    lastName: z.string().min(1).max(100),
    street: z.string().min(1).max(200),
    houseNumber: z.string().min(1).max(10),
    addressAddition: z.string().max(100).optional(),
    zip: z.string().min(1).max(10),
    city: z.string().min(1).max(50),
    countryCode: z.string().min(2).max(2),
    phone: z.string().max(20).optional(),
  })
  .passthrough();

export const CheckoutDto = z
  .object({
    products: z.array(CartProduct).min(1).max(1),
    deliveryAddress: AddressDto.optional(),
  })
  .passthrough();

export const PurchaseDto = z
  .object({
    id: z.string(),
    userId: z.string(),
    totalPrice: z.number(),
    deliveryAddress: AddressDto.optional(),
  })
  .passthrough();

export const CreateOrUpdateChallengeArgs = z
  .object({
    name: z.string().min(1).max(150),
    typeOfChallenge: z.enum(["LOCATION", "APP", "CAR", "PURCHASE"]),
    badgeId: z.string().min(1).max(100),
    tokens: z.number().gte(1).optional(),
    rules: z.object({}).partial().passthrough().optional(),
    slug: z.string().min(1).max(150).optional(),
    useExistingContentfulEntry: z.boolean().optional(),
    startDate: z.number().gte(0).optional(),
    endDate: z.number().gte(0).optional(),
    previousChallengeId: z.string().min(1).max(100).optional(),
    checkInAmount: z.number().gte(1).optional(),
    coolDown: z.number().gte(1).optional(),
  })
  .passthrough();

export const ChallengeDto = z
  .object({
    name: z.string(),
    slug: z.string(),
    status: z.string(),
    typeOfChallenge: z.string(),
    badgeId: z.string(),
    rules: z.object({}).partial().passthrough(),
    tokens: z.number().optional(),
    startDate: z.number().optional(),
    endDate: z.number().optional(),
    id: z.string(),
    creation: z.number().optional(),
    updated: z.number().optional(),
    baseChallengeId: z.string().optional(),
    previousChallengeId: z.string().optional(),
    checkInAmount: z.number().optional(),
    coolDown: z.number().optional(),
  })
  .passthrough();

export const UserChallengeDto = z
  .object({
    name: z.string(),
    slug: z.string(),
    status: z.string(),
    typeOfChallenge: z.string(),
    badgeId: z.string(),
    rules: z.object({}).partial().passthrough(),
    tokens: z.number().optional(),
    startDate: z.number().optional(),
    endDate: z.number().optional(),
    id: z.string(),
    creation: z.number().optional(),
    updated: z.number().optional(),
    baseChallengeId: z.string().optional(),
    previousChallengeId: z.string().optional(),
    checkInAmount: z.number().optional(),
    coolDown: z.number().optional(),
    isEligible: z.boolean(),
    isFinished: z.boolean(),
    coolDownUntil: z.number().optional(),
    totalCheckIns: z.number().optional(),
    title: z.string(),
    description: z.object({}).partial().passthrough(),
    image: z.object({}).partial().passthrough(),
    backgroundColor: z.string(),
    address: z.string().optional(),
    coordinates: z.object({}).partial().passthrough().optional(),
    animation: z.object({}).partial().passthrough(),
  })
  .passthrough();

export const StatsOverviewDto = z
  .object({
    mintedTokens: z.number(),
    usedTokens: z.number(),
    expiringTokens: z.number(),
    expiringTokensUntil: z.number(),
    createdBadges: z.string(),
    finishedChallenges: z.number(),
    claimedBenefits: z.number(),
    participationRate: z.number(),
    averageTransactionValue: z.number(),
    challengesCompletionRate: z.number(),
    tokenRedemptionRate: z.number(),
    benefitsRedemptionRate: z.number(),
    onboardedUsers: z.number(),
  })
  .passthrough();

export const StatsBadgeDto = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    cid: z.string(),
    contractAddress: z.string(),
    tokenId: z.number(),
    creation: z.number(),
    updated: z.number(),
    createdCount: z.string(),
  })
  .passthrough();

export const StatsChallengeDto = z
  .object({
    name: z.string(),
    slug: z.string(),
    status: z.string(),
    typeOfChallenge: z.string(),
    badgeId: z.string(),
    rules: z.object({}).partial().passthrough(),
    tokens: z.number().optional(),
    startDate: z.number().optional(),
    endDate: z.number().optional(),
    id: z.string(),
    creation: z.number().optional(),
    updated: z.number().optional(),
    baseChallengeId: z.string().optional(),
    previousChallengeId: z.string().optional(),
    checkInAmount: z.number().optional(),
    coolDown: z.number().optional(),
    finishedCount: z.number(),
  })
  .passthrough();

export const StatsBenefitDto = z
  .object({
    id: z.string(),
    name: z.string(),
    contentfulRef: z.string(),
    status: z.string(),
    timeActive: z.number(),
    claimedCount: z.number(),
  })
  .passthrough();

export const StatReportDto = z.object({ report: z.string() }).passthrough();

export const BenefitDto = z
  .object({
    id: z.string(),
    name: z.string(),
    contentfulRef: z.string(),
    status: z.string(),
    timeActive: z.number(),
  })
  .passthrough();

export const BenefitClaimedDto = z
  .object({
    userId: z.string(),
    benefitId: z.string(),
    activeUntil: z.number(),
  })
  .passthrough();

export const UserBenefitDto = z
  .object({
    id: z.string(),
    name: z.string(),
    contentfulRef: z.string(),
    status: z.string(),
    timeActive: z.number(),
    activeUntil: z.number().optional(),
    isEligible: z.boolean().optional(),
    title: z.string(),
    subTitle: z.string().optional(),
    description: z.object({}).partial().passthrough(),
    discountCode: z.string().optional(),
    image: z.object({}).partial().passthrough().optional(),
    url: z.string().optional(),
    address: z.string().optional(),
    coordinates: z.object({}).partial().passthrough().optional(),
    dbRef: z.string(),
    animation: z.object({}).partial().passthrough(),
  })
  .passthrough();

export const CreateBenefitArgs = z
  .object({
    name: z.string().min(1).max(100),
    contentfulRef: z.string().min(1).max(100),
    timeActive: z.number().gte(1),
    useExistingContentfulEntry: z.boolean(),
  })
  .partial()
  .passthrough();

export const UpdateBenefitArgs = z
  .object({
    name: z.string().min(1).max(100),
    contentfulRef: z.string().min(1).max(100),
    timeActive: z.number().gte(1),
    useExistingContentfulEntry: z.boolean(),
  })
  .partial()
  .passthrough();

export const UpdateBenefitStatusArgs = z
  .object({ status: z.enum(["DRAFT", "ACTIVE", "DELETED"]) })
  .passthrough();

export const CronJobDto = z
  .object({ name: z.string(), cron: z.string(), next: z.string() })
  .passthrough();

export const StudioUserDto = z
  .object({
    userId: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    lastCheckInAt: z.number(),
  })
  .passthrough();

export const StudioOverviewDto = z
  .object({
    studioChallenge: ChallengeDto,
    usersLast24h: z.array(StudioUserDto),
  })
  .passthrough();

export const StudioCommentDto = z
  .object({
    id: z.string(),
    userId: z.string(),
    comment: z.string(),
    creatorId: z.string(),
    creation: z.number(),
    creatorName: z.string().optional(),
  })
  .passthrough();

export const StudioBenefitDto = z
  .object({ name: z.string(), timesClaimed: z.number() })
  .passthrough();

export const StudioChallengeDto = z
  .object({ name: z.string(), badgeCid: z.string(), finishedAt: z.number() })
  .passthrough();

export const StudioSpendingInfoDto = z
  .object({
    tokens: z.number(),
    spentTokens: z.number(),
    purchases: z.number(),
  })
  .passthrough();

export const StudioCarInfoDto = z
  .object({
    currentCarType: z.string(),
    currentCarPrice: z.number(),
    currentCarCurrency: z.string(),
    invoiceDate: z.number(),
    previousCarType: z.string().optional(),
  })
  .passthrough();

export const StudioUserDetailsDto = z
  .object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    walletAddress: z.string().optional(),
    deleted: z.boolean().optional(),
    blacklisted: z.boolean().optional(),
    comments: z.array(StudioCommentDto),
    benefits: z.array(StudioBenefitDto),
    challenges: z.array(StudioChallengeDto),
    spendingInfo: StudioSpendingInfoDto,
    carInfos: z.array(StudioCarInfoDto),
  })
  .passthrough();

export const CreateOrEditCommentArgs = z
  .object({ comment: z.string().min(1).max(200) })
  .passthrough();

export const WalletBalanceDto = z
  .object({ balance: z.string(), tokenBalance: z.number() })
  .passthrough();

const AdminBadgeController_create_ZodResponseSchema = BadgeDto;
type AdminBadgeController_create_ZodResponseSchemaType =
  typeof AdminBadgeController_create_ZodResponseSchema;
export const AdminBadgeController_create: ApiRequest<AdminBadgeController_create_ZodResponseSchemaType> =
  {
    path: "/admin/badge",
    method: "post",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateBadgeArgs,
      },
    ],
    responseSchema: BadgeDto,
  };

const AdminBadgeController_getAll_ZodResponseSchema = z.array(BadgeDto);
type AdminBadgeController_getAll_ZodResponseSchemaType =
  typeof AdminBadgeController_getAll_ZodResponseSchema;
export const AdminBadgeController_getAll: ApiRequest<AdminBadgeController_getAll_ZodResponseSchemaType> =
  {
    path: "/admin/badge",
    method: "get",
    parameters: [
      {
        name: "contractAddress",
        type: "Query",
        schema: z.string().min(42).max(42).optional(),
      },
    ],
    responseSchema: z.array(BadgeDto),
  };

const AdminBadgeController_getBadgeByID_ZodResponseSchema = BadgeDto;
type AdminBadgeController_getBadgeByID_ZodResponseSchemaType =
  typeof AdminBadgeController_getBadgeByID_ZodResponseSchema;
export const AdminBadgeController_getBadgeByID: ApiRequest<AdminBadgeController_getBadgeByID_ZodResponseSchemaType> =
  {
    path: "/admin/badge/:id",
    method: "get",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string().min(1).max(100),
      },
    ],
    responseSchema: BadgeDto,
  };

const AdminBenefitController_create_ZodResponseSchema = BenefitDto;
type AdminBenefitController_create_ZodResponseSchemaType =
  typeof AdminBenefitController_create_ZodResponseSchema;
export const AdminBenefitController_create: ApiRequest<AdminBenefitController_create_ZodResponseSchemaType> =
  {
    path: "/admin/benefit",
    method: "post",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateBenefitArgs,
      },
    ],
    responseSchema: BenefitDto,
  };

const AdminBenefitController_getAllNonDeletedBenefitsWithCmsData_ZodResponseSchema =
  z.array(BenefitDto);
type AdminBenefitController_getAllNonDeletedBenefitsWithCmsData_ZodResponseSchemaType =
  typeof AdminBenefitController_getAllNonDeletedBenefitsWithCmsData_ZodResponseSchema;
export const AdminBenefitController_getAllNonDeletedBenefitsWithCmsData: ApiRequest<AdminBenefitController_getAllNonDeletedBenefitsWithCmsData_ZodResponseSchemaType> =
  {
    path: "/admin/benefit",
    method: "get",
    parameters: [],
    responseSchema: z.array(BenefitDto),
  };

const AdminBenefitController_update_ZodResponseSchema = BenefitDto;
type AdminBenefitController_update_ZodResponseSchemaType =
  typeof AdminBenefitController_update_ZodResponseSchema;
export const AdminBenefitController_update: ApiRequest<AdminBenefitController_update_ZodResponseSchemaType> =
  {
    path: "/admin/benefit/:benefitId",
    method: "put",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateBenefitArgs,
      },
      {
        name: "benefitId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: BenefitDto,
  };

const AdminBenefitController_deleteBenefit_ZodResponseSchema = BenefitDto;
type AdminBenefitController_deleteBenefit_ZodResponseSchemaType =
  typeof AdminBenefitController_deleteBenefit_ZodResponseSchema;
export const AdminBenefitController_deleteBenefit: ApiRequest<AdminBenefitController_deleteBenefit_ZodResponseSchemaType> =
  {
    path: "/admin/benefit/:benefitId",
    method: "delete",
    parameters: [
      {
        name: "benefitId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: BenefitDto,
  };

const AdminBenefitController_updateStatus_ZodResponseSchema = BenefitDto;
type AdminBenefitController_updateStatus_ZodResponseSchemaType =
  typeof AdminBenefitController_updateStatus_ZodResponseSchema;
export const AdminBenefitController_updateStatus: ApiRequest<AdminBenefitController_updateStatus_ZodResponseSchemaType> =
  {
    path: "/admin/benefit/:benefitId/status",
    method: "put",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateBenefitStatusArgs,
      },
      {
        name: "benefitId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: BenefitDto,
  };

const AdminChallengeController_create_ZodResponseSchema = ChallengeDto;
type AdminChallengeController_create_ZodResponseSchemaType =
  typeof AdminChallengeController_create_ZodResponseSchema;
export const AdminChallengeController_create: ApiRequest<AdminChallengeController_create_ZodResponseSchemaType> =
  {
    path: "/admin/challenge",
    method: "post",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateOrUpdateChallengeArgs,
      },
    ],
    responseSchema: ChallengeDto,
  };

const AdminChallengeController_getAllChallenges_ZodResponseSchema =
  z.array(ChallengeDto);
type AdminChallengeController_getAllChallenges_ZodResponseSchemaType =
  typeof AdminChallengeController_getAllChallenges_ZodResponseSchema;
export const AdminChallengeController_getAllChallenges: ApiRequest<AdminChallengeController_getAllChallenges_ZodResponseSchemaType> =
  {
    path: "/admin/challenge",
    method: "get",
    parameters: [
      {
        name: "status",
        type: "Query",
        schema: z.array(z.enum(["ACTIVE", "DRAFT"])).optional(),
      },
      {
        name: "type",
        type: "Query",
        schema: z
          .array(z.enum(["LOCATION", "APP", "CAR", "PURCHASE"]))
          .optional(),
      },
    ],
    responseSchema: z.array(ChallengeDto),
  };

const AdminChallengeController_updateChallenge_ZodResponseSchema = ChallengeDto;
type AdminChallengeController_updateChallenge_ZodResponseSchemaType =
  typeof AdminChallengeController_updateChallenge_ZodResponseSchema;
export const AdminChallengeController_updateChallenge: ApiRequest<AdminChallengeController_updateChallenge_ZodResponseSchemaType> =
  {
    path: "/admin/challenge/:challengeId",
    method: "put",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateOrUpdateChallengeArgs,
      },
      {
        name: "challengeId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: ChallengeDto,
  };

const AdminChallengeController_deleteChallenge_ZodResponseSchema = ChallengeDto;
type AdminChallengeController_deleteChallenge_ZodResponseSchemaType =
  typeof AdminChallengeController_deleteChallenge_ZodResponseSchema;
export const AdminChallengeController_deleteChallenge: ApiRequest<AdminChallengeController_deleteChallenge_ZodResponseSchemaType> =
  {
    path: "/admin/challenge/:challengeId",
    method: "delete",
    parameters: [
      {
        name: "challengeId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: ChallengeDto,
  };

const AdminChallengeController_getChallengeById_ZodResponseSchema =
  ChallengeDto;
type AdminChallengeController_getChallengeById_ZodResponseSchemaType =
  typeof AdminChallengeController_getChallengeById_ZodResponseSchema;
export const AdminChallengeController_getChallengeById: ApiRequest<AdminChallengeController_getChallengeById_ZodResponseSchemaType> =
  {
    path: "/admin/challenge/:challengeId",
    method: "get",
    parameters: [
      {
        name: "challengeId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: ChallengeDto,
  };

const AdminChallengeController_changeChallengeStatus_ZodResponseSchema =
  ChallengeDto;
type AdminChallengeController_changeChallengeStatus_ZodResponseSchemaType =
  typeof AdminChallengeController_changeChallengeStatus_ZodResponseSchema;
export const AdminChallengeController_changeChallengeStatus: ApiRequest<AdminChallengeController_changeChallengeStatus_ZodResponseSchemaType> =
  {
    path: "/admin/challenge/:challengeId/status",
    method: "put",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ChangeStatusArgs,
      },
      {
        name: "challengeId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: ChallengeDto,
  };

const AdminChallengeController_adminFinishUserChallenge_ZodResponseSchema =
  UserChallengeDto;
type AdminChallengeController_adminFinishUserChallenge_ZodResponseSchemaType =
  typeof AdminChallengeController_adminFinishUserChallenge_ZodResponseSchema;
export const AdminChallengeController_adminFinishUserChallenge: ApiRequest<AdminChallengeController_adminFinishUserChallenge_ZodResponseSchemaType> =
  {
    path: "/admin/challenge/user/:userId/:challengeId",
    method: "post",
    parameters: [
      {
        name: "challengeId",
        type: "Path",
        schema: z.string().max(100),
      },
      {
        name: "userId",
        type: "Path",
        schema: z.string().min(1).max(100),
      },
    ],
    responseSchema: UserChallengeDto,
  };

const AdminProductController_create_ZodResponseSchema = ProductDto;
type AdminProductController_create_ZodResponseSchemaType =
  typeof AdminProductController_create_ZodResponseSchema;
export const AdminProductController_create: ApiRequest<AdminProductController_create_ZodResponseSchemaType> =
  {
    path: "/admin/product",
    method: "post",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateProductArgs,
      },
    ],
    responseSchema: ProductDto,
  };

const AdminProductController_getAll_ZodResponseSchema = z.array(ProductDto);
type AdminProductController_getAll_ZodResponseSchemaType =
  typeof AdminProductController_getAll_ZodResponseSchema;
export const AdminProductController_getAll: ApiRequest<AdminProductController_getAll_ZodResponseSchemaType> =
  {
    path: "/admin/product",
    method: "get",
    parameters: [],
    responseSchema: z.array(ProductDto),
  };

const AdminProductController_getProductByID_ZodResponseSchema = ProductDto;
type AdminProductController_getProductByID_ZodResponseSchemaType =
  typeof AdminProductController_getProductByID_ZodResponseSchema;
export const AdminProductController_getProductByID: ApiRequest<AdminProductController_getProductByID_ZodResponseSchemaType> =
  {
    path: "/admin/product/:id",
    method: "get",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: ProductDto,
  };

const AdminProductController_update_ZodResponseSchema = ProductDto;
type AdminProductController_update_ZodResponseSchemaType =
  typeof AdminProductController_update_ZodResponseSchema;
export const AdminProductController_update: ApiRequest<AdminProductController_update_ZodResponseSchemaType> =
  {
    path: "/admin/product/:id",
    method: "put",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateProductArgs,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: ProductDto,
  };

const AdminProductController_delete_ZodResponseSchema = z.void();
type AdminProductController_delete_ZodResponseSchemaType =
  typeof AdminProductController_delete_ZodResponseSchema;
export const AdminProductController_delete: ApiRequest<AdminProductController_delete_ZodResponseSchemaType> =
  {
    path: "/admin/product/:id",
    method: "delete",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: z.void(),
  };

const AdminProductController_changeStatus_ZodResponseSchema = ProductDto;
type AdminProductController_changeStatus_ZodResponseSchemaType =
  typeof AdminProductController_changeStatus_ZodResponseSchema;
export const AdminProductController_changeStatus: ApiRequest<AdminProductController_changeStatus_ZodResponseSchemaType> =
  {
    path: "/admin/product/:id/status",
    method: "put",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ChangeStatusArgs,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: ProductDto,
  };

const SettingsController_deleteCache_ZodResponseSchema = z.void();
type SettingsController_deleteCache_ZodResponseSchemaType =
  typeof SettingsController_deleteCache_ZodResponseSchema;
export const SettingsController_deleteCache: ApiRequest<SettingsController_deleteCache_ZodResponseSchemaType> =
  {
    path: "/admin/settings/cache/delete",
    method: "put",
    parameters: [],
    responseSchema: z.void(),
  };

const SettingsController_getCronJobs_ZodResponseSchema = z.array(CronJobDto);
type SettingsController_getCronJobs_ZodResponseSchemaType =
  typeof SettingsController_getCronJobs_ZodResponseSchema;
export const SettingsController_getCronJobs: ApiRequest<SettingsController_getCronJobs_ZodResponseSchemaType> =
  {
    path: "/admin/settings/cron",
    method: "get",
    parameters: [],
    responseSchema: z.array(CronJobDto),
  };

const SettingsController_getOperationalStatus_ZodResponseSchema = z.boolean();
type SettingsController_getOperationalStatus_ZodResponseSchemaType =
  typeof SettingsController_getOperationalStatus_ZodResponseSchema;
export const SettingsController_getOperationalStatus: ApiRequest<SettingsController_getOperationalStatus_ZodResponseSchemaType> =
  {
    path: "/admin/settings/operation",
    method: "get",
    parameters: [],
    responseSchema: z.boolean(),
  };

const SettingsController_resumeOperation_ZodResponseSchema = z.boolean();
type SettingsController_resumeOperation_ZodResponseSchemaType =
  typeof SettingsController_resumeOperation_ZodResponseSchema;
export const SettingsController_resumeOperation: ApiRequest<SettingsController_resumeOperation_ZodResponseSchemaType> =
  {
    path: "/admin/settings/operation/resume",
    method: "put",
    parameters: [],
    responseSchema: z.boolean(),
  };

const SettingsController_suspendOperation_ZodResponseSchema = z.boolean();
type SettingsController_suspendOperation_ZodResponseSchemaType =
  typeof SettingsController_suspendOperation_ZodResponseSchema;
export const SettingsController_suspendOperation: ApiRequest<SettingsController_suspendOperation_ZodResponseSchemaType> =
  {
    path: "/admin/settings/operation/suspend",
    method: "put",
    parameters: [],
    responseSchema: z.boolean(),
  };

const AdminStatsController_getBadgesStats_ZodResponseSchema =
  z.array(StatsBadgeDto);
type AdminStatsController_getBadgesStats_ZodResponseSchemaType =
  typeof AdminStatsController_getBadgesStats_ZodResponseSchema;
export const AdminStatsController_getBadgesStats: ApiRequest<AdminStatsController_getBadgesStats_ZodResponseSchemaType> =
  {
    path: "/admin/stats/badges",
    method: "get",
    parameters: [],
    responseSchema: z.array(StatsBadgeDto),
  };

const AdminStatsController_getBenefitsStats_ZodResponseSchema =
  z.array(StatsBenefitDto);
type AdminStatsController_getBenefitsStats_ZodResponseSchemaType =
  typeof AdminStatsController_getBenefitsStats_ZodResponseSchema;
export const AdminStatsController_getBenefitsStats: ApiRequest<AdminStatsController_getBenefitsStats_ZodResponseSchemaType> =
  {
    path: "/admin/stats/benefits",
    method: "get",
    parameters: [],
    responseSchema: z.array(StatsBenefitDto),
  };

const AdminStatsController_getChallengesStats_ZodResponseSchema =
  z.array(StatsChallengeDto);
type AdminStatsController_getChallengesStats_ZodResponseSchemaType =
  typeof AdminStatsController_getChallengesStats_ZodResponseSchema;
export const AdminStatsController_getChallengesStats: ApiRequest<AdminStatsController_getChallengesStats_ZodResponseSchemaType> =
  {
    path: "/admin/stats/challenges",
    method: "get",
    parameters: [],
    responseSchema: z.array(StatsChallengeDto),
  };

const AdminStatsController_getOverview_ZodResponseSchema = StatsOverviewDto;
type AdminStatsController_getOverview_ZodResponseSchemaType =
  typeof AdminStatsController_getOverview_ZodResponseSchema;
export const AdminStatsController_getOverview: ApiRequest<AdminStatsController_getOverview_ZodResponseSchemaType> =
  {
    path: "/admin/stats/overview",
    method: "get",
    parameters: [
      {
        name: "expiringTokensUntil",
        type: "Query",
        schema: z.number().gte(1).optional(),
      },
    ],
    responseSchema: StatsOverviewDto,
  };

const AdminStatsController_getTokenBalanceReportCsvBase64_ZodResponseSchema = z
  .object({ report: z.string() })
  .passthrough();
type AdminStatsController_getTokenBalanceReportCsvBase64_ZodResponseSchemaType =
  typeof AdminStatsController_getTokenBalanceReportCsvBase64_ZodResponseSchema;
export const AdminStatsController_getTokenBalanceReportCsvBase64: ApiRequest<AdminStatsController_getTokenBalanceReportCsvBase64_ZodResponseSchemaType> =
  {
    path: "/admin/stats/token-balance-report",
    method: "get",
    parameters: [],
    responseSchema: z.object({ report: z.string() }).passthrough(),
  };

const AdminStatsController_getTokenIssuanceReport_ZodResponseSchema = z
  .object({ report: z.string() })
  .passthrough();
type AdminStatsController_getTokenIssuanceReport_ZodResponseSchemaType =
  typeof AdminStatsController_getTokenIssuanceReport_ZodResponseSchema;
export const AdminStatsController_getTokenIssuanceReport: ApiRequest<AdminStatsController_getTokenIssuanceReport_ZodResponseSchemaType> =
  {
    path: "/admin/stats/token-issuance-report",
    method: "get",
    parameters: [],
    responseSchema: z.object({ report: z.string() }).passthrough(),
  };

const AdminStatsController_getTokenSpendingReportCsvBase64_ZodResponseSchema = z
  .object({ report: z.string() })
  .passthrough();
type AdminStatsController_getTokenSpendingReportCsvBase64_ZodResponseSchemaType =
  typeof AdminStatsController_getTokenSpendingReportCsvBase64_ZodResponseSchema;
export const AdminStatsController_getTokenSpendingReportCsvBase64: ApiRequest<AdminStatsController_getTokenSpendingReportCsvBase64_ZodResponseSchemaType> =
  {
    path: "/admin/stats/token-spending-report",
    method: "get",
    parameters: [],
    responseSchema: z.object({ report: z.string() }).passthrough(),
  };

const TokenAdminController_stats_ZodResponseSchema = StatsDto;
type TokenAdminController_stats_ZodResponseSchemaType =
  typeof TokenAdminController_stats_ZodResponseSchema;
export const TokenAdminController_stats: ApiRequest<TokenAdminController_stats_ZodResponseSchemaType> =
  {
    path: "/admin/token/stats",
    method: "get",
    parameters: [
      {
        name: "expiringTokensUntil",
        type: "Query",
        schema: z.number().gte(1).optional(),
      },
    ],
    responseSchema: StatsDto,
  };

const AdminUserController_getAllUsers_ZodResponseSchema = GetAllUsers;
type AdminUserController_getAllUsers_ZodResponseSchemaType =
  typeof AdminUserController_getAllUsers_ZodResponseSchema;
export const AdminUserController_getAllUsers: ApiRequest<AdminUserController_getAllUsers_ZodResponseSchemaType> =
  {
    path: "/admin/user",
    method: "get",
    parameters: [
      {
        name: "itemsPerPage",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "limitKeyId",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    responseSchema: GetAllUsers,
  };

const AdminUserController_getUsersByIds_ZodResponseSchema =
  z.array(UserAdminDto);
type AdminUserController_getUsersByIds_ZodResponseSchemaType =
  typeof AdminUserController_getUsersByIds_ZodResponseSchema;
export const AdminUserController_getUsersByIds: ApiRequest<AdminUserController_getUsersByIds_ZodResponseSchemaType> =
  {
    path: "/admin/user/batch",
    method: "get",
    parameters: [
      {
        name: "userIds",
        type: "Query",
        schema: z.array(z.string()),
      },
    ],
    responseSchema: z.array(UserAdminDto),
  };

const AdminUserController_updateBlacklistStatus_ZodResponseSchema = z.void();
type AdminUserController_updateBlacklistStatus_ZodResponseSchemaType =
  typeof AdminUserController_updateBlacklistStatus_ZodResponseSchema;
export const AdminUserController_updateBlacklistStatus: ApiRequest<AdminUserController_updateBlacklistStatus_ZodResponseSchemaType> =
  {
    path: "/admin/user/blacklist",
    method: "put",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UserBlacklistArgs,
      },
    ],
    responseSchema: z.void(),
  };

const AdminUserController_getAdminProfile_ZodResponseSchema = UserDetailsDto;
type AdminUserController_getAdminProfile_ZodResponseSchemaType =
  typeof AdminUserController_getAdminProfile_ZodResponseSchema;
export const AdminUserController_getAdminProfile: ApiRequest<AdminUserController_getAdminProfile_ZodResponseSchemaType> =
  {
    path: "/admin/user/me",
    method: "get",
    parameters: [],
    responseSchema: UserDetailsDto,
  };

const AdminUserController_updatePurchaseStatus_ZodResponseSchema =
  z.array(UserPurchaseDto);
type AdminUserController_updatePurchaseStatus_ZodResponseSchemaType =
  typeof AdminUserController_updatePurchaseStatus_ZodResponseSchema;
export const AdminUserController_updatePurchaseStatus: ApiRequest<AdminUserController_updatePurchaseStatus_ZodResponseSchemaType> =
  {
    path: "/admin/user/purchase/:id/status",
    method: "put",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ChangePurchaseStatusArgs,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: z.array(UserPurchaseDto),
  };

const AdminUserController_getAllUserPurchases_ZodResponseSchema =
  GetAllUserPurchases;
type AdminUserController_getAllUserPurchases_ZodResponseSchemaType =
  typeof AdminUserController_getAllUserPurchases_ZodResponseSchema;
export const AdminUserController_getAllUserPurchases: ApiRequest<AdminUserController_getAllUserPurchases_ZodResponseSchemaType> =
  {
    path: "/admin/user/purchases",
    method: "get",
    parameters: [
      {
        name: "itemsPerPage",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "limitKeyId",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "status",
        type: "Query",
        schema: z.enum(["INITIAL", "IN_PROGRESS", "FULFILLED"]).optional(),
      },
      {
        name: "limitKeyUserId",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    responseSchema: GetAllUserPurchases,
  };

const AuthController_authenticate_ZodResponseSchema = AuthResponseDto;
type AuthController_authenticate_ZodResponseSchemaType =
  typeof AuthController_authenticate_ZodResponseSchema;
export const AuthController_authenticate: ApiRequest<AuthController_authenticate_ZodResponseSchemaType> =
  {
    path: "/auth/authenticate",
    method: "post",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ code: z.string().min(1).max(3000) }).passthrough(),
      },
    ],
    responseSchema: AuthResponseDto,
  };

const BenefitController_getAllEligibleAndClaimed_ZodResponseSchema =
  z.array(UserBenefitDto);
type BenefitController_getAllEligibleAndClaimed_ZodResponseSchemaType =
  typeof BenefitController_getAllEligibleAndClaimed_ZodResponseSchema;
export const BenefitController_getAllEligibleAndClaimed: ApiRequest<BenefitController_getAllEligibleAndClaimed_ZodResponseSchemaType> =
  {
    path: "/benefit",
    method: "get",
    parameters: [
      {
        name: "status",
        type: "Query",
        schema: z.array(z.enum(["CURRENTLY_ACTIVE", "ELIGIBLE"])).optional(),
      },
      {
        name: "language",
        type: "Query",
        schema: z.literal("en-US").optional(),
      },
      {
        name: "populate",
        type: "Query",
        schema: z.enum(["true", "false"]),
      },
    ],
    responseSchema: z.array(UserBenefitDto),
  };

const BenefitController_getBenefit_ZodResponseSchema = BenefitDto;
type BenefitController_getBenefit_ZodResponseSchemaType =
  typeof BenefitController_getBenefit_ZodResponseSchema;
export const BenefitController_getBenefit: ApiRequest<BenefitController_getBenefit_ZodResponseSchemaType> =
  {
    path: "/benefit/:benefitId",
    method: "get",
    parameters: [
      {
        name: "benefitId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: BenefitDto,
  };

const BenefitController_claimBenefit_ZodResponseSchema = BenefitClaimedDto;
type BenefitController_claimBenefit_ZodResponseSchemaType =
  typeof BenefitController_claimBenefit_ZodResponseSchema;
export const BenefitController_claimBenefit: ApiRequest<BenefitController_claimBenefit_ZodResponseSchemaType> =
  {
    path: "/benefit/:benefitId/claim",
    method: "put",
    parameters: [
      {
        name: "benefitId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: BenefitClaimedDto,
  };

const CartController_checkout_ZodResponseSchema = PurchaseDto;
type CartController_checkout_ZodResponseSchemaType =
  typeof CartController_checkout_ZodResponseSchema;
export const CartController_checkout: ApiRequest<CartController_checkout_ZodResponseSchemaType> =
  {
    path: "/cart/checkout",
    method: "post",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CheckoutDto,
      },
    ],
    responseSchema: PurchaseDto,
  };

const ChainController_getBadgesData_ZodResponseSchema =
  z.array(BadgeChainDataDto);
type ChainController_getBadgesData_ZodResponseSchemaType =
  typeof ChainController_getBadgesData_ZodResponseSchema;
export const ChainController_getBadgesData: ApiRequest<ChainController_getBadgesData_ZodResponseSchemaType> =
  {
    path: "/chain/badges",
    method: "get",
    parameters: [],
    responseSchema: z.array(BadgeChainDataDto),
  };

const ChainController_getGeneralData_ZodResponseSchema = BasicChainDataDto;
type ChainController_getGeneralData_ZodResponseSchemaType =
  typeof ChainController_getGeneralData_ZodResponseSchema;
export const ChainController_getGeneralData: ApiRequest<ChainController_getGeneralData_ZodResponseSchemaType> =
  {
    path: "/chain/general",
    method: "get",
    parameters: [],
    responseSchema: BasicChainDataDto,
  };

const ChallengeController_getAllActiveOrFutureOrFinished_ZodResponseSchema =
  z.array(UserChallengeDto);
type ChallengeController_getAllActiveOrFutureOrFinished_ZodResponseSchemaType =
  typeof ChallengeController_getAllActiveOrFutureOrFinished_ZodResponseSchema;
export const ChallengeController_getAllActiveOrFutureOrFinished: ApiRequest<ChallengeController_getAllActiveOrFutureOrFinished_ZodResponseSchemaType> =
  {
    path: "/challenge",
    method: "get",
    parameters: [
      {
        name: "status",
        type: "Query",
        schema: z.array(z.enum(["FUTURE", "FINISHED", "ELIGIBLE"])).optional(),
      },
      {
        name: "type",
        type: "Query",
        schema: z
          .array(z.enum(["LOCATION", "APP", "CAR", "PURCHASE"]))
          .optional(),
      },
      {
        name: "language",
        type: "Query",
        schema: z.literal("en-US").optional(),
      },
      {
        name: "populate",
        type: "Query",
        schema: z.enum(["true", "false"]),
      },
    ],
    responseSchema: z.array(UserChallengeDto),
  };

const ChallengeController_finishChallenge_ZodResponseSchema = UserChallengeDto;
type ChallengeController_finishChallenge_ZodResponseSchemaType =
  typeof ChallengeController_finishChallenge_ZodResponseSchema;
export const ChallengeController_finishChallenge: ApiRequest<ChallengeController_finishChallenge_ZodResponseSchemaType> =
  {
    path: "/challenge/:challengeId/finish",
    method: "post",
    parameters: [
      {
        name: "challengeId",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: UserChallengeDto,
  };

const ChallengeController_getAllFinishedChallenges_ZodResponseSchema =
  z.array(ChallengeDto);
type ChallengeController_getAllFinishedChallenges_ZodResponseSchemaType =
  typeof ChallengeController_getAllFinishedChallenges_ZodResponseSchema;
export const ChallengeController_getAllFinishedChallenges: ApiRequest<ChallengeController_getAllFinishedChallenges_ZodResponseSchemaType> =
  {
    path: "/challenge/finished",
    method: "get",
    parameters: [],
    responseSchema: z.array(ChallengeDto),
  };

const AppController_healthCheck_ZodResponseSchema = z.void();
type AppController_healthCheck_ZodResponseSchemaType =
  typeof AppController_healthCheck_ZodResponseSchema;
export const AppController_healthCheck: ApiRequest<AppController_healthCheck_ZodResponseSchemaType> =
  {
    path: "/healthCheck",
    method: "get",
    parameters: [],
    responseSchema: z.void(),
  };

const MetadataController_getMetadataByAddressAndTokenId_ZodResponseSchema = z
  .object({})
  .partial()
  .passthrough();
type MetadataController_getMetadataByAddressAndTokenId_ZodResponseSchemaType =
  typeof MetadataController_getMetadataByAddressAndTokenId_ZodResponseSchema;
export const MetadataController_getMetadataByAddressAndTokenId: ApiRequest<MetadataController_getMetadataByAddressAndTokenId_ZodResponseSchemaType> =
  {
    path: "/metadata/:contractAddress/:tokenId",
    method: "get",
    parameters: [
      {
        name: "contractAddress",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "tokenId",
        type: "Path",
        schema: z.number().gte(0),
      },
    ],
    responseSchema: z.object({}).partial().passthrough(),
  };

const MetadataController_getContractMetadata_ZodResponseSchema = z
  .object({})
  .partial()
  .passthrough();
type MetadataController_getContractMetadata_ZodResponseSchemaType =
  typeof MetadataController_getContractMetadata_ZodResponseSchema;
export const MetadataController_getContractMetadata: ApiRequest<MetadataController_getContractMetadata_ZodResponseSchemaType> =
  {
    path: "/metadata/:contractAddress/contractMetadata",
    method: "get",
    parameters: [
      {
        name: "contractAddress",
        type: "Path",
        schema: z.string(),
      },
    ],
    responseSchema: z.object({}).partial().passthrough(),
  };

const ProductController_getAll_ZodResponseSchema = z.array(ProductDto);
type ProductController_getAll_ZodResponseSchemaType =
  typeof ProductController_getAll_ZodResponseSchema;
export const ProductController_getAll: ApiRequest<ProductController_getAll_ZodResponseSchemaType> =
  {
    path: "/product",
    method: "get",
    parameters: [
      {
        name: "type",
        type: "Query",
        schema: z.enum(["VOUCHER", "EVENT", "ITEM"]).optional(),
      },
    ],
    responseSchema: z.array(ProductDto),
  };

const ProductController_getProductByID_ZodResponseSchema = ProductDto;
type ProductController_getProductByID_ZodResponseSchemaType =
  typeof ProductController_getProductByID_ZodResponseSchema;
export const ProductController_getProductByID: ApiRequest<ProductController_getProductByID_ZodResponseSchemaType> =
  {
    path: "/product/:id",
    method: "get",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string().max(100),
      },
    ],
    responseSchema: ProductDto,
  };

const ProductController_getProductBySlug_ZodResponseSchema = ProductDto;
type ProductController_getProductBySlug_ZodResponseSchemaType =
  typeof ProductController_getProductBySlug_ZodResponseSchema;
export const ProductController_getProductBySlug: ApiRequest<ProductController_getProductBySlug_ZodResponseSchemaType> =
  {
    path: "/product/slug/:slug",
    method: "get",
    parameters: [
      {
        name: "slug",
        type: "Path",
        schema: z.string().max(150),
      },
    ],
    responseSchema: ProductDto,
  };

const StudioStudioController_getOverview_ZodResponseSchema = StudioOverviewDto;
type StudioStudioController_getOverview_ZodResponseSchemaType =
  typeof StudioStudioController_getOverview_ZodResponseSchema;
export const StudioStudioController_getOverview: ApiRequest<StudioStudioController_getOverview_ZodResponseSchemaType> =
  {
    path: "/studio/studio",
    method: "get",
    parameters: [],
    responseSchema: StudioOverviewDto,
  };

const StudioStudioController_getComments_ZodResponseSchema =
  z.array(StudioCommentDto);
type StudioStudioController_getComments_ZodResponseSchemaType =
  typeof StudioStudioController_getComments_ZodResponseSchema;
export const StudioStudioController_getComments: ApiRequest<StudioStudioController_getComments_ZodResponseSchemaType> =
  {
    path: "/studio/studio/comments/:userId",
    method: "get",
    parameters: [
      {
        name: "userId",
        type: "Path",
        schema: z.string().min(1).max(100),
      },
    ],
    responseSchema: z.array(StudioCommentDto),
  };

const StudioStudioController_addComment_ZodResponseSchema = StudioCommentDto;
type StudioStudioController_addComment_ZodResponseSchemaType =
  typeof StudioStudioController_addComment_ZodResponseSchema;
export const StudioStudioController_addComment: ApiRequest<StudioStudioController_addComment_ZodResponseSchemaType> =
  {
    path: "/studio/studio/comments/:userId",
    method: "post",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ comment: z.string().min(1).max(200) }).passthrough(),
      },
      {
        name: "userId",
        type: "Path",
        schema: z.string().min(1).max(100),
      },
    ],
    responseSchema: StudioCommentDto,
  };

const StudioStudioController_editComment_ZodResponseSchema = StudioCommentDto;
type StudioStudioController_editComment_ZodResponseSchemaType =
  typeof StudioStudioController_editComment_ZodResponseSchema;
export const StudioStudioController_editComment: ApiRequest<StudioStudioController_editComment_ZodResponseSchemaType> =
  {
    path: "/studio/studio/comments/:userId/:id",
    method: "put",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ comment: z.string().min(1).max(200) }).passthrough(),
      },
      {
        name: "userId",
        type: "Path",
        schema: z.string().min(1).max(100),
      },
      {
        name: "id",
        type: "Path",
        schema: z.string().min(1).max(100),
      },
    ],
    responseSchema: StudioCommentDto,
  };

const StudioStudioController_deleteComment_ZodResponseSchema = z.void();
type StudioStudioController_deleteComment_ZodResponseSchemaType =
  typeof StudioStudioController_deleteComment_ZodResponseSchema;
export const StudioStudioController_deleteComment: ApiRequest<StudioStudioController_deleteComment_ZodResponseSchemaType> =
  {
    path: "/studio/studio/comments/:userId/:id",
    method: "delete",
    parameters: [
      {
        name: "userId",
        type: "Path",
        schema: z.string().min(1).max(100),
      },
      {
        name: "id",
        type: "Path",
        schema: z.string().min(1).max(100),
      },
    ],
    responseSchema: z.void(),
  };

const StudioStudioController_getUserDetails_ZodResponseSchema =
  StudioUserDetailsDto;
type StudioStudioController_getUserDetails_ZodResponseSchemaType =
  typeof StudioStudioController_getUserDetails_ZodResponseSchema;
export const StudioStudioController_getUserDetails: ApiRequest<StudioStudioController_getUserDetails_ZodResponseSchemaType> =
  {
    path: "/studio/studio/user/:userId",
    method: "get",
    parameters: [
      {
        name: "userId",
        type: "Path",
        schema: z.string().min(1).max(100),
      },
    ],
    responseSchema: StudioUserDetailsDto,
  };

const StudioStudioController_search_ZodResponseSchema = z.array(StudioUserDto);
type StudioStudioController_search_ZodResponseSchemaType =
  typeof StudioStudioController_search_ZodResponseSchema;
export const StudioStudioController_search: ApiRequest<StudioStudioController_search_ZodResponseSchemaType> =
  {
    path: "/studio/studio/user/search",
    method: "get",
    parameters: [
      {
        name: "searchString",
        type: "Query",
        schema: z.string().min(1).max(50),
      },
    ],
    responseSchema: z.array(StudioUserDto),
  };

const StudioUserController_getStudioProfile_ZodResponseSchema = UserDetailsDto;
type StudioUserController_getStudioProfile_ZodResponseSchemaType =
  typeof StudioUserController_getStudioProfile_ZodResponseSchema;
export const StudioUserController_getStudioProfile: ApiRequest<StudioUserController_getStudioProfile_ZodResponseSchemaType> =
  {
    path: "/studio/user/me",
    method: "get",
    parameters: [],
    responseSchema: UserDetailsDto,
  };

const TokenController_balance_ZodResponseSchema = BalanceDto;
type TokenController_balance_ZodResponseSchemaType =
  typeof TokenController_balance_ZodResponseSchema;
export const TokenController_balance: ApiRequest<TokenController_balance_ZodResponseSchemaType> =
  {
    path: "/token/balance",
    method: "get",
    parameters: [
      {
        name: "expiringTokensUntil",
        type: "Query",
        schema: z.number().gte(1).optional(),
      },
    ],
    responseSchema: BalanceDto,
  };

const UserController_deleteUser_ZodResponseSchema = z.void();
type UserController_deleteUser_ZodResponseSchemaType =
  typeof UserController_deleteUser_ZodResponseSchema;
export const UserController_deleteUser: ApiRequest<UserController_deleteUser_ZodResponseSchemaType> =
  {
    path: "/user",
    method: "delete",
    parameters: [],
    responseSchema: z.void(),
  };

const UserController_getUserCheckoutData_ZodResponseSchema =
  UserCheckoutDetailsDto;
type UserController_getUserCheckoutData_ZodResponseSchemaType =
  typeof UserController_getUserCheckoutData_ZodResponseSchema;
export const UserController_getUserCheckoutData: ApiRequest<UserController_getUserCheckoutData_ZodResponseSchemaType> =
  {
    path: "/user/checkout/data",
    method: "get",
    parameters: [],
    responseSchema: UserCheckoutDetailsDto,
  };

const UserController_getMyProfile_ZodResponseSchema = UserDetailsDto;
type UserController_getMyProfile_ZodResponseSchemaType =
  typeof UserController_getMyProfile_ZodResponseSchema;
export const UserController_getMyProfile: ApiRequest<UserController_getMyProfile_ZodResponseSchemaType> =
  {
    path: "/user/me",
    method: "get",
    parameters: [],
    responseSchema: UserDetailsDto,
  };

const UserController_reEnableUser_ZodResponseSchema = z.void();
type UserController_reEnableUser_ZodResponseSchemaType =
  typeof UserController_reEnableUser_ZodResponseSchema;
export const UserController_reEnableUser: ApiRequest<UserController_reEnableUser_ZodResponseSchemaType> =
  {
    path: "/user/reenable",
    method: "put",
    parameters: [],
    responseSchema: z.void(),
  };

const UserController_createTanganyWallet_ZodResponseSchema = WalletDto;
type UserController_createTanganyWallet_ZodResponseSchemaType =
  typeof UserController_createTanganyWallet_ZodResponseSchema;
export const UserController_createTanganyWallet: ApiRequest<UserController_createTanganyWallet_ZodResponseSchemaType> =
  {
    path: "/user/wallet",
    method: "post",
    parameters: [],
    responseSchema: WalletDto,
  };
